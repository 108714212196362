import * as React from "react";
import Header from '../elements/Header';
import Footer from '../elements/Footer';
import Newsletter from '../elements/Newsletter';
import { graphql } from 'gatsby';
import { SEO } from '../components/SeoNew';
import Navigation from "../components/Navigation"
import { Container } from "../components/Container"
import { PageContainer } from "../components/PageContainer"

const PrivacyPolicyPage = () => (
  <>
    <PageContainer>
      <Navigation />
      <Container className="mt-16 mb-16">
        <h1
          className="font-display text-5xl sm:text-7xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] ">
          Privacy Policy
        </h1>
        <div className="prose prose-blue w-full max-w-full prose-lg text-gray-500 mx-auto mt-8">
          <p>
            This privacy policy explains how www.ndna.io collects, uses, and shares
            information when you use our contact form.
          </p>
          <h2>Information We Collect</h2>
          <p>We collect the following information when you use our contact form:</p>
          <ul>
            <li>Your email address</li>
          </ul>
          <p>
            We use the information you provide in the form to respond to your inquiries and to
            communicate with you. We may also use your email address to send you newsletters
            or promotional materials if you opt-in to receive them.
          </p>
          <h2>Sharing Your Information</h2>
          <p>We do not share your personal information with any third parties, except as necessary to fulfill your
            request or as required by law.</p>
          <h2>Changes to Our Privacy Policy</h2>
          <p>We may update our privacy policy from time to time. We will post any changes on this page and encourage
            you to review our privacy policy whenever you access our contact form.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions or concerns about our privacy policy, please contact us at <a
            href="mailto:contact@ndna.io">contact@ndna.io</a></p>
        </div>
      </Container>
    </PageContainer>
    <Footer />
  </>
);

export default PrivacyPolicyPage

export const query = graphql`
query($language: String!) {
locales: allLocale(filter
:
{
  language: {
    eq: $language
  }
}
)
{
  edges
  {
    node
    {
      ns
      data
      language
    }
  }
}
}
`;

export const Head = () =>
<>
  <SEO title="Privacy Policy" />
</>
